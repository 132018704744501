import React, { useState, useEffect, useCallback } from "react";
import QRIcon from "../src/assests/qr.svg";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code"; // Import the QRCode component
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Connection, Keypair, LAMPORTS_PER_SOL, SystemProgram, Transaction, ComputeBudgetProgram } from '@solana/web3.js';
import { toast } from 'react-toastify';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import PawLogo from "../src/assests/paypawlogo.png";
import invoice from "../src/assests/invoice.svg";
import solLogo from "../src/assests/currency-sol-logo.png";
import btcLogo from "../src/assests/currency-btc-logo.png";
import ethLogo from "../src/assests/currency-eth-logo.png";
import bonkLogo from "../src/assests/currency-bonk-logo.png";
import usdcLogo from "../src/assests/currency-usdc-logo.png";
import usdtLogo from "../src/assests/currency-usdt-logo.png";
import dogeLogo from "../src/assests/currency-doge-logo.png";
import shibLogo from "../src/assests/currency-shib-logo.png";
import pepeLogo from "../src/assests/currency-pepe-logo.png";
import suiLogo from "../src/assests/currency-sui-logo.png";
import xmrLogo from "../src/assests/currency-xmr-logo.svg";
import copyImg from "../src/assests/copy.svg";
import important from "../src/assests/i-icon.svg";

const PaymentQrCode = () => { // Accept qrCodeData as a prop
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('waiting'); // Default to 'waiting'
  const [emailScan, setEmailScan] = useState(false);
  const [copiedA, setCopiedA] = useState(false);
  const [copiedT, setCopiedT] = useState(false);
  const { qrCodeData, tradeId, address, amount, ticker, emailProvided } = location.state;
  const tickerImg = {
    'sol': solLogo,
    'btc': btcLogo,
    'eth': ethLogo,
    'bonk': bonkLogo,
    'usdc': usdcLogo,
    'usdt': usdtLogo,
    'doge': dogeLogo,
    'shib': shibLogo,
    'pepe': pepeLogo,
    'sui': suiLogo,
    'xmr': xmrLogo
  }

  const connection = new Connection('https://nadya-vc2kmh-fast-mainnet.helius-rpc.com');
  const { publicKey, sendTransaction } = useWallet();

  function round(x) {
    const num = Number.parseFloat(x);
    const roundedUp = Math.ceil(num * 1e6) / 1e6;
    return roundedUp.toFixed(6);
  }

  const handleSendSol = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    
    try {
      const lamports = amount * LAMPORTS_PER_SOL;
      const transaction = new Transaction().add(
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: 1500,
      })
      ).add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: address,
          lamports,
        })
      );

      const signature = await sendTransaction(transaction, connection);
      toast(`Transaction sent: ${signature}`);
    } catch (error) {
      console.error("Error handling Sol Payment:", error);
    }
  }, [publicKey, sendTransaction, connection]);

  const checkPaymentStatus = async () => {
    try {
      const response = await fetch(`https://paypawcards.com/paypawbackend/trade-status/${tradeId}?source=paypaw`);
      if (!response.ok) {
        throw new Error('Failed to fetch trade status');
      }
      const data = await response.json();

      // Update payment status based on API response
      setPaymentStatus(data.status);

      // Check if the status is no longer "waiting"
      if (data.status !== "waiting") {
        // If there's a cardLink and the payment is finished, navigate to GetYourCard
        if (data.cardLink) {
          navigate("/get-your-card", { state: { redeemLink: data.cardLink, redeemCode: data.cardCode } });
        } else {
          // The payment has been received, and we're waiting for the card to be created.
          // No further actions needed here since UI update is handled by the state change.
        }
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };

  const watchForEmail = async () => {
    if (!emailProvided || emailScan) {
      return;
    }
    setEmailScan(true);
    try {
      const response = await fetch(`https://paypawcards.com/paypawbackend/watch-for-email/${tradeId}?source=paypaw`);
      if (!response.ok) {
        throw new Error('Failed to get email');
      }

    } catch (error) {
      console.error("Error getting email:", error);
    }
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(address);
    setCopiedA(true);
    setTimeout(() => {
      setCopiedA(false);
    }, 2000); // Reset copied state after 2 seconds
  };

  const copyTradeID = () => {
    navigator.clipboard.writeText(tradeId);
    setCopiedT(true);
    setTimeout(() => {
      setCopiedT(false);
    }, 2000); // Reset copied state after 2 seconds
  };
  


  useEffect(() => {
    if (emailProvided) {
      watchForEmail();
    }
    
    const intervalId = setInterval(() => {
      checkPaymentStatus();
    }, 60000); 

    return () => {
      clearInterval(intervalId);
      //clearInterval(intervalTimeoutId);
    }; // Cleanup on unmount
  }, []);

  // Render different content based on the payment status
  let content;
  if (paymentStatus === 'waiting') {
    content = (
      <>
        <div className="flex items-center justify-center w-full p-6">
          { 
            ticker === "sol" ?
            <div className="bg-white p-4 rounded-lg w-40 h-40">
              <QRCode value={qrCodeData} size={128} level="M" className="mx-auto" />
            </div>
            :
            ''
          }
        </div>
        <div className="pb-2">
          <strong>Manual Transfer:</strong>
          <br/>
          <span className="flex items-center justify-center"><span className="pe-2">Send exactly</span><span className="ticker-amount ps-1 pe-1"><img src={tickerImg[ticker]} alt="ticker logo" className="max-h-4"/>{round(amount)}</span><span className="ps-2"> to the following address</span></span>
          <br/>
          <div className="flex items-center justify-center">
            <div className="address-width">
              <input
                type="text"
                value={address}
                readOnly
                className="flex-1 py-2 px-3 address-frame rounded-l-md focus:outline-none"
              />
              <button
                onClick={copyAddress}
                className="px-6 py-3 copy-btn text-white rounded-r-md hover:opacity-80 "
              >
                {copiedA ? 'Copied!' : <img src={copyImg} alt="copy logo" className="max-h-6"/>}
              </button>
            </div>
          </div>
          <br/>
          <strong>Trade ID:</strong>
          <br/>
          <div className="flex items-center justify-center">
            <div className="trade-width">
              <input
                type="text"
                value={tradeId}
                readOnly
                className="flex-1 py-2 px-2 address-frame rounded-l-md focus:outline-none"
              />
              <button
                onClick={copyTradeID}
                className="px-6 py-3 copy-btn text-white rounded-r-md hover:opacity-80 "
              >
                {copiedT ? 'Copied!' : <img src={copyImg} alt="copy logo" className="max-h-6"/>}
              </button>
            </div>
          </div>
          <br/>
          <div className="flex items-center justify-center">
            <div className="warning-frame">
              <div className="ps-4 pt-3 flex items-center">
                <span><img src={important} alt="important" className="max-h-6 warn-text"/></span><span className="warn-text ps-3">Make sure to save your Trade ID above!</span>
              </div>
              <div className="ps-11 flex items-start pb-2">
                <span>Do NOT refresh this page once the payment has gone through</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center w-full pb-6">
          <strong>Currently Awaiting Payment...</strong>
        </div>
      </>
    );
  } else if (paymentStatus === 'expired') {
    content = <h2 className="text-lg font-semibold mt-4 pb-2">Oh no! Payment is expired. Please start again.</h2>;
  } else {
    content = (
      <div className="p-6">
        <h2 className="text-lg font-semibold">We got your payment!</h2>
        
        <br/>
        <br/>
        <div className="flex items-center justify-center w-full">
          <strong>Currently Awaiting Card Creation...</strong>
        </div>
        <div>
        <br/>
        <strong>Trade ID:</strong>
          <br/>
          <div className="flex items-center justify-center">
            <div className="trade-width">
              <input
                type="text"
                value={tradeId}
                readOnly
                className="flex-1 py-2 px-2 address-frame rounded-l-md focus:outline-none"
              />
              <button
                onClick={copyTradeID}
                className="px-6 py-3 copy-btn text-white rounded-r-md hover:opacity-80 "
              >
                {copiedT ? 'Copied!' : <img src={copyImg} alt="copy logo" className="max-h-6"/>}
              </button>
            </div>
          </div>
          <br/>
          <strong>If you entered your email you will get a copy of the card redemption link.</strong>
          <br/>
          <br/>
          <div className="flex items-center justify-center">
            <div className="warning-frame">
              <div className="ps-4 pt-3 flex items-center">
                <span><img src={important} alt="important" className="max-h-6 warn-text"/></span><span className="warn-text ps-3">Make sure to save your Trade ID above!</span>
              </div>
              <div className="ps-11 flex items-start">
                <span>Do NOT refresh this page once the payment has gone through</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-center pb-2">
        <div className="step-box border-fuchsia-800">
          <img src={invoice} alt="invoice" className="max-h-6 step-img flex justify-center items-center"/>
        </div>
      </div>
      <div>
        <div className="text-white flex justify-center items-center pb-1">
          <strong>Send your payment</strong>
        </div>
        <div className="gray-text flex justify-center items-center pb-4">
          Send a payment to recieve your card
        </div>
        <hr className="step-divider"></hr>
        <div className="pb-2"></div>
      </div>
      <div className="bg-nav-bar gray-border rounded-lg text-white text-center w-96 md:w-607">
        {content}
      </div>
    </div>
  );

};

export default PaymentQrCode;
